import { CategoryModel } from '~/models';
import placeholder from '~/assets/images/placeholder.png';
import { Button, Typography } from '../ui';
import { cn } from '~/lib/utils';
import { Link, useNavigate } from '@remix-run/react';

interface Props
{
  category: CategoryModel;
  index: number;
  fromCarrousel?: boolean;
  url?: string;
  className?: string;
  reloadDocument?: boolean;
  overrideClick?: (url: string) => void;
}

export function CategoriesGalleryItem({
  category,
  index,
  fromCarrousel,
  url,
  className,
  reloadDocument,
  overrideClick
}: Props)
{
  const navigate = useNavigate();
  const smallBaseClassesNames = 'h-44 md:h-64 md:pt-12 justify-end';
  const inner_className =
    index === 0
      ? fromCarrousel
        ? smallBaseClassesNames + ''
        : 'col-span-full justify-end relative'
      : smallBaseClassesNames;
  const classNameGeneral = cn(['p-6 flex flex-col rounded-xl ', inner_className, className]);
  return (
    <>
      <div
        className={ classNameGeneral }
        style={ {
          backgroundImage: `url('${ category.image?.src || placeholder }')`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        } }
      >
        <Typography
          variant="headline6"
          className={ `text-white align-top text-left mb-3 text-ellipsis overflow-hidden z-[2] sm-max:mb-1 sm-max:text-[16px] ${ index != 0 || fromCarrousel === true
            ? 'w-full'
            : 'md:w-[209px] md:text-justify'
            }` }
        >
          { category.name }
        </Typography>
        { overrideClick != undefined ?
          <Button
            className={ `h-12 sm-max:h-10 z-[2] bg-cool_blue hover:bg-cool_blue rounded-md text-center flex flex-col justify-center ${ index != 0 || fromCarrousel === true ? 'w-full' : 'md:w-[159px]'
              }` }
            onClick={ () => overrideClick(`${ category.handle }`) }
          >
            <Typography variant="button" className='whitespace-nowrap'>View More</Typography>
          </Button>
          :
          <Link
            prefetch='intent'
            className={ `h-12 sm-max:h-10 z-[2] bg-cool_blue rounded-md text-center flex flex-col justify-center ${ index != 0 || fromCarrousel === true ? 'w-full' : 'md:w-[159px]'
              }` }
            to={ url || `/categories/${ category.handle }` }
            reloadDocument={ reloadDocument }
          >
            <Typography variant="button" className='whitespace-nowrap'>View More</Typography>
          </Link>
        }
      </div>
      <div className="rounded-xl h-full absolute bottom-0 w-full bg-gradient-to-t from-black opacity-70  "></div>
    </>
  );
}
