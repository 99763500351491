import { CategoryModel } from '~/models';
import { Button, Carousel, Typography } from '../ui';
import { CategoriesGalleryItem } from './CategoriesGalleryItem.component';
import { cn } from '~/lib/utils';
import { useResponsive } from '~/hooks';
import { useEffect, useState } from 'react';
import { Categories5, MainBannerLandingPage, ReadyMadeMealsBanner, organicCorn, vegetablesForBarbecue } from '~/assets/images';

interface Props {
  categories: Array<CategoryModel>;
  to?: string;
  title?: string;
  description?: string;
  allCarousel?: boolean;
  useBreakpoints?: boolean;
  className?: string;
  categoryItemClassName?: string;
  slidesPerView?: number | 'auto';
  previewNextSlide?: number;
  swiperSlideClassName?: string;
  localRedirection?: boolean;
  overrideClick?: (url:string) => void;
}

export function CategoriesGallery({
  categories,
  title,
  description,
  allCarousel = false,
  useBreakpoints = true,
  className,
  to,
  categoryItemClassName,
  slidesPerView,
  previewNextSlide,
  swiperSlideClassName,
  localRedirection = false,
  overrideClick
}: Props) {
  const { isMobileOnly, isTablet } = useResponsive();
  const [newCarrousel, setNewCarrousel] = useState<Array<CategoryModel>>([]);
  const carrousel = allCarousel
    ? categories
    : categories.slice(1);

  /* TODO: This is a temporary solution, where I've added 2 times the numbers of categories to make the carousel loop works.
  The loop with autoplay works only if you array has 9+ of items. */
  useEffect(() => {
    let newCarrousel =  carrousel;
    if(carrousel.length <= 8 && carrousel.length >= 5){
      newCarrousel.push(...carrousel);
    }

    setNewCarrousel(newCarrousel);
  },[]);
  

  return (
    <div className={cn('mt-14', className)} id='LandingCategories'>
      { title ? <Typography variant='headline3' type='h2' className='lg-max:mb-2'> {title}</Typography> : ''}
      { description ? <Typography variant="body1" dangerouslySetInnerHTML={{ __html: description }} className="my-4" /> : '' }
      
      { to 
        ? <div className="flex justify-end mb-5">
          { to && (
            <Button
              className="px-10 md:px-6 bg-primary !no-underline text-white"
              variant="link"
              to={to}
            >
              <Typography variant="button">View More</Typography>
            </Button>
          )}
        </div>
      : ''
    }
      
      {!allCarousel && (
        <div className="relative h-96">
          <CategoriesGalleryItem
            className={`md:h-full h-full`}
            category={categories[0]}
            index={0}
            key={'category-' + categories[0].name + '-1'}
            reloadDocument={!localRedirection}
          />
        </div>
      )}
      <Carousel
        slidesPerView={slidesPerView ? slidesPerView: (isMobileOnly) ? 2 : isTablet ? 3 : 5}
        spaceBetween={10}
        autoplay
        loop
        className="mt-2 md:mt-2"
        swiperSlideClassName={cn("cursor-context-menu w-64", swiperSlideClassName)}
        previewNextSlide={isMobileOnly? 0 : 0.125}
      >
        {newCarrousel && newCarrousel.map((category: CategoryModel, index: number) => {
          return (
            <CategoriesGalleryItem
              fromCarrousel={true}
              category={category}
              index={index}
              key={'category-' + category.name + '-' + index+ '-' + category.id}
              url={localRedirection ? `#${category.handle}` : undefined}
              className={categoryItemClassName}
              reloadDocument={!localRedirection}
              overrideClick={overrideClick}
            />
          );
        })}
      </Carousel>
    </div>
  );
}

const DUMMY_CAT: Array<CategoryModel> = [
    {
      id: "9a324525-835d-498d-a1bd-64f93ebf3eb0",
      name: 'Meats & Seafood',
      image:{
        src: Categories5,}
      },
    {
      "id": "6efaaf1d-ae66-4797-97f3-433306f8b1fb",
      "name": "Beverages",
      image:{
        src: MainBannerLandingPage,
      }
    },
    {
      "id": "f99769e6-2c5b-47ad-a532-cda7a603d5ae",
      "name": "Fruits, nuts and vegetables",
      image:{
        src: ReadyMadeMealsBanner,
      }
    },
    {
      id: "dece0d7d-4485-4ca5-af87-d75858e02d50",
      name: "Snacks",
      image:{
        src: organicCorn,
      }
    },
    {
      "id": "d69f8fde-0a9a-43ee-bf57-0f027cbeca16",
      "name": "Desserts and breads",
      image:{
        src: vegetablesForBarbecue,
      }
    },
  ];
